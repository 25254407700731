import { DocumentType, ProfessionalType } from '@innedit/innedit-type';
import compact from 'lodash/compact';
import { ProfessionalData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Tag from '~/components/Tag';
import { ListItemProps } from '~/containers/Espace/List/props';
import IconUserCircle from '~/icons/UserCircle';

import ListItem from './index';

const ListItemProfessional: FC<
  ListItemProps<ProfessionalType, ProfessionalData>
> = ({ docId, index, model, onClick }) => {
  const [doc, setDoc] = useState<DocumentType<ProfessionalType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const label = compact(
    model.labelFields.map(field => (doc[field] ? doc[field] : undefined)),
  ).join(' - ');

  return (
    <ListItem
      displayActionId={false}
      doc={doc}
      icon={doc.uid && <IconUserCircle />}
      index={index}
      label={label}
      onClick={onClick}
    >
      <div className="flex space-x-3 items-center">
        {doc.stripeAccountId && <Tag>Compte connecté</Tag>}
        {doc.stripeAccountVerified && <Tag>Vérifié</Tag>}
      </div>
    </ListItem>
  );
};

export default ListItemProfessional;
